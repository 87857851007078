import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button, ErrorSummary } from 'govuk-react-jsx';
import { useDispatch, useSelector } from 'react-redux';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import {
  checkValidName,
  checkValidRoleName,
  checkValidNumberWithZero,
  checkValidNumberWithoutZero,
} from '../../regex/regex';
import {
  setAccountFirstName,
  setAccountLastName,
  setAccountRoleName,
  setAccountMobileNumber,
  setAccountTelephoneNumber,
  getAccountFirstName,
  getAccountLastName,
  getAccountRoleName,
  getAccountMobileNumber,
  getAccountTelephoneNumber,
} from '../../features/registration/registrationJourneySlice';
import {
  getCurrentLanguage,
  getCopyProviderCY,
  getCopyProviderEN,
} from '../../features/app/appSlice';
import useTitle from '../../hooks/useTitle';
import { updateContactDetails } from '../../firebase';

const ContactDetails = () => {
  const sendDataToGTM = useGTMDispatch();
  const firstNameRedux = useSelector(getAccountFirstName);
  const lastNameRedux = useSelector(getAccountLastName);
  const roleNameRedux = useSelector(getAccountRoleName);
  const mobileNumberRedux = useSelector(getAccountMobileNumber);
  const telephoneNumberRedux = useSelector(getAccountTelephoneNumber);
  const [errorMsgFirstName, setErrorMsgFirstName] = useState('');
  const [errorMsgLastName, setErrorMsgLastName] = useState('');
  const [errorMsgRole, setErrorMsgRole] = useState('');
  const [errorMsgMobile, setErrorMsgMobile] = useState('');
  const [errorMsgTelephone, setErrorMsgTelephone] = useState('');
  const [errorList, setErrorList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const currentLanguage = useSelector(getCurrentLanguage);
  const copyProviderCY = useSelector(getCopyProviderCY);
  const copyProviderEN = useSelector(getCopyProviderEN);
  const copyProvider =
    currentLanguage === 'en' ? copyProviderEN : copyProviderCY;

  useTitle(
    'SMEReg.ContactDetails.s18',
    'Enter your contact details - OZEV Electric Vehicle Chargepoint Grant - GOV.UK'
  );

  const updateFirstName = (event) => {
    dispatch(setAccountFirstName(event.target.value));
  };
  const updateLastName = (event) => {
    dispatch(setAccountLastName(event.target.value));
  };
  const updatedRoleName = (event) => {
    dispatch(setAccountRoleName(event.target.value));
  };
  const updatedMobileNumber = (event) => {
    dispatch(setAccountMobileNumber(event.target.value));
  };
  const updatedTelephoneNumber = (event) => {
    dispatch(setAccountTelephoneNumber(event.target.value));
  };

  const errorFirstName = copyProvider.getCopy(
    'SMEReg.ContactDetails.s1',
    'Enter a valid first name'
  );

  const errorLastName = copyProvider.getCopy(
    'SMEReg.ContactDetails.s16',
    'Enter a valid last name'
  );

  const errorRoleMsg = copyProvider.getCopy(
    'SMEReg.ContactDetails.s2',
    'Enter a valid business role'
  );

  const errorMobileMsg = copyProvider.getCopy(
    'SMEReg.ContactDetails.s3',
    'Enter a valid mobile phone number'
  );

  const errorTelephoneMsg = copyProvider.getCopy(
    'SMEReg.ContactDetails.s4',
    'Enter a valid office landline number'
  );
  const errorAlreadyExistsMsg = copyProvider.getCopy(
    'landlordReg.ContactDetails.s17',
    'An account already exists with this mobile number'
  );
  const handleBtnClick = () => {
    let hasError = false;
    sendDataToGTM({
      event: 'reg_contact_details',
      category: 'Registration',
    });
    const errorListArr = [];
    const showErrorFirstName = {
      children: errorFirstName,
    };
    const showErrorLastName = {
      children: errorLastName,
    };
    const showErrorRole = {
      children: errorRoleMsg,
    };
    const showErrorMobileNum = {
      children: errorMobileMsg,
    };
    const showErrorTelephoneNum = {
      children: errorTelephoneMsg,
    };
    const showErrorAlreadyExists = {
      children: errorAlreadyExistsMsg,
    };
    // set or clear the error for each field
    if (!checkValidName.test(firstNameRedux)) {
      setErrorMsgFirstName(showErrorFirstName);
      errorListArr.push({ ...showErrorFirstName, href: '#first-name' });
      hasError = true;
    } else {
      setErrorMsgFirstName(null);
    }

    if (!checkValidName.test(lastNameRedux)) {
      setErrorMsgLastName(showErrorLastName);
      errorListArr.push({ ...showErrorLastName, href: '#last-name' });
      hasError = true;
    } else {
      setErrorMsgLastName(null);
    }

    if (!checkValidRoleName.test(roleNameRedux)) {
      setErrorMsgRole(showErrorRole);
      errorListArr.push({ ...showErrorRole, href: '#Role' });
      hasError = true;
    } else {
      setErrorMsgRole(null);
    }
    if (mobileNumberRedux === '') {
      setErrorMsgMobile(showErrorMobileNum);
      errorListArr.push({ ...showErrorMobileNum, href: '#mobile-number' });
      hasError = true;
    } else if (mobileNumberRedux.charCodeAt(0) === 48) {
      if (!checkValidNumberWithZero.test(mobileNumberRedux)) {
        setErrorMsgMobile(showErrorMobileNum);
        errorListArr.push({
          ...showErrorMobileNum,
          href: '#mobile-number',
        });
        hasError = true;
      } else {
        setErrorMsgMobile(null);
      }
    } else if (mobileNumberRedux.charCodeAt(0) !== 48) {
      if (!checkValidNumberWithoutZero.test(mobileNumberRedux)) {
        setErrorMsgMobile(showErrorMobileNum);
        errorListArr.push({ ...showErrorMobileNum, href: '#mobile-number' });
        hasError = true;
      } else {
        setErrorMsgMobile(null);
      }
    }

    if (telephoneNumberRedux === '') {
      setErrorMsgTelephone(null);
    } else if (telephoneNumberRedux.charCodeAt(0) === 48) {
      if (!checkValidNumberWithZero.test(telephoneNumberRedux)) {
        setErrorMsgTelephone(showErrorTelephoneNum);
        errorListArr.push({ ...showErrorMobileNum, href: '#Telephone-number' });
        hasError = true;
      } else {
        setErrorMsgTelephone(null);
      }
    } else if (telephoneNumberRedux.charCodeAt(0) !== 48) {
      if (!checkValidNumberWithoutZero.test(telephoneNumberRedux)) {
        setErrorMsgTelephone(showErrorTelephoneNum);
        errorListArr.push({ ...showErrorMobileNum, href: '#Telephone-number' });
        hasError = true;
      } else {
        setErrorMsgTelephone(null);
      }
    }
    if (!hasError) {
      updateContactDetails({
        firstName: firstNameRedux,
        lastName: lastNameRedux,
        roleName: roleNameRedux,
        mobileNumber: mobileNumberRedux,
        telephoneNumber: telephoneNumberRedux,
      })
        .then(() => {
          dispatch(setAccountFirstName(firstNameRedux));
          dispatch(setAccountLastName(lastNameRedux));
          dispatch(setAccountRoleName(roleNameRedux));
          dispatch(setAccountMobileNumber(mobileNumberRedux));
          dispatch(setAccountTelephoneNumber(telephoneNumberRedux));
          history.push('/sme-reg/access-code-mobile');
        })
        .catch((error) => {
          // TODO: add error when the server is not responding
          // TODO: add error if invalid arguments passed
          if (error.code === 'functions/already-exists') {
            errorListArr.push({
              ...showErrorAlreadyExists,
              href: '#mobile-number',
            });
            setErrorList(errorListArr);
            setErrorMsgMobile(showErrorAlreadyExists);
          }
        });
    } else {
      setErrorList(errorListArr);
    }
  };

  const pageModel = (
    <>
      {errorList?.length > 0 && (
        <ErrorSummary
          errorList={errorList}
          titleChildren={copyProvider.getCopy(
            'Common.error-summery',
            'There is a problem'
          )}
        />
      )}
      <h1 className="govuk-heading-xl">
        {copyProvider.getCopy(
          'landlordReg.ContactDetails.s5',
          'Enter your contact details'
        )}
      </h1>
      <p className="govuk-body govuk-margin-bottom-2">
        {copyProvider.getCopy(
          'landlordReg.ContactDetails.s6',
          'We will use your contact details to confirm your application and to complete the account process.'
        )}
      </p>

      <div className="govuk-form-group">
        <Input
          id="first-name"
          errorMessage={errorMsgFirstName || null}
          label={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s7',
              'First name'
            ),
          }}
          name="first-name"
          type="text"
          maxLength="250"
          value={firstNameRedux}
          onChange={(event) => updateFirstName(event)}
        />
        <Input
          id="last-name"
          errorMessage={errorMsgLastName || null}
          label={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s8',
              'Last name'
            ),
          }}
          name="last-name"
          type="text"
          maxLength="250"
          value={lastNameRedux}
          onChange={(event) => updateLastName(event)}
        />
        <Input
          id="Role"
          errorMessage={errorMsgRole || null}
          label={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s9',
              'Your role in the company'
            ),
          }}
          hint={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s10',
              'For example, property owner or managing director.'
            ),
          }}
          name="Role"
          type="text"
          maxLength="250"
          value={roleNameRedux}
          onChange={(event) => updatedRoleName(event)}
        />
        <Input
          id="mobile-number"
          label={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s11',
              'Mobile phone number'
            ),
          }}
          hint={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s12',
              'We will send you a verification code to verify your phone number.'
            ),
          }}
          errorMessage={errorMsgMobile || null}
          name="mobile-number"
          prefix={{
            children: '+44',
          }}
          type="number"
          className="govuk-input govuk-input--width-10"
          value={mobileNumberRedux}
          onChange={(event) => updatedMobileNumber(event)}
        />
        <Input
          errorMessage={errorMsgTelephone || null}
          id="Telephone-number"
          label={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s13',
              'Office landline number (optional)'
            ),
          }}
          hint={{
            children: copyProvider.getCopy(
              'landlordReg.ContactDetails.s15',
              'We will use your mobile or office landline number to contact you if we need more information.'
            ),
          }}
          name="Telephone-number"
          prefix={{
            children: '+44',
          }}
          type="number"
          className="govuk-input govuk-input--width-10"
          value={telephoneNumberRedux}
          onChange={(event) => updatedTelephoneNumber(event)}
        />
      </div>
      <Button className="govuk-button" onClick={handleBtnClick}>
        {copyProvider.getCopy('landlordReg.ContactDetails.s14', 'Continue')}
      </Button>
    </>
  );

  return pageModel;
};

export default ContactDetails;
